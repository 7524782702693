var viewModel = {
    Attendees: ko.observableArray([]),
    Addons: ko.observableArray([]),
    OverbookedMessage: ko.observable(""),
    OverbookedShortMessage: ko.observable(""),
    Tickets: ko.observableArray([]),
    Errors: ko.observable("").extend({ notify: 'always' })
};

var ticketModel = function (id, name, description, taxable, price, quantity, categoryDisplayOrder, displayOrder, invoicing, isDonation, continuingEducationUnits, attendeeRequired, showOnForm, noGuestsAllowed) {
    var self = this;
    this.ID = id;
    this.Name = name;
    this.Description = description;
    this.Taxable = taxable;
    this.CategoryDisplayOrder = categoryDisplayOrder;
    this.DisplayOrder = displayOrder;
    this.Price = ko.observable(price);
    this.Quantity = ko.observable(quantity);
    this.Invoicing = ko.observable(invoicing);
    this.IsDonation = isDonation;
    this.ContinuingEducationUnits = continuingEducationUnits;
    this.AttendeeRequired = ko.observable(attendeeRequired);
    this.ShowOnForm = ko.observable(showOnForm);
    this.NoGuestsAllowed = ko.observable(noGuestsAllowed);
    this.SubTotal = ko.computed(function () {
        return self.Quantity() * self.Price();
    });
}

var addonModel = function (id, name, description, taxable, subscription, price, quantity, autoRenew) {
    var self = this;
    this.ID = id;
    this.Name = name;
    this.Description = description;
    this.Taxable = taxable;
    this.Subscription = subscription;
    this.Price = ko.observable(price);
    this.Quantity = ko.observable(quantity);
    this.AutoRenew = ko.observable(autoRenew);
    this.SubTotal = ko.computed(function () {
        return self.Quantity() * self.Price();
    });

}

viewModel.Overbooked = ko.computed(function () {
    var seatsPurchased = viewModel.Attendees().length;
    var seatsLeft = eventCapacity - seatsPurchased;
    var overbooked = seatsLeft < 0;
    var pluralizeEventCapacity = eventCapacity > 1 ? "s" : "";
    var pluralizeSeatsPurchasedd = seatsPurchased > 1 ? "s" : "";

    if (overbooked) {
        viewModel.OverbookedMessage("Event over capacity: The event has capacity for " + eventCapacity + " more attendee" + pluralizeEventCapacity + ", but you have selected " + seatsPurchased + " ticket" + pluralizeSeatsPurchasedd + ". Please update ticket selections above to reflect the available number.");
        viewModel.OverbookedShortMessage("Event only has " + eventCapacity + " ticket" + pluralizeEventCapacity + " available.");
    }
    else {
        viewModel.OverbookedMessage("");
        viewModel.OverbookedShortMessage("");
    }

    return overbooked;
});

$(function () {
    //ko.applyBindings(viewModel);

    function UpdateTotals() {
        var tickets = [];
        var attendees = [];
        $("table.tickets-table tbody tr").each(function () {
            var qtyElement = $(this).find("input.qty-total");
            if (qtyElement.length == 0)
                return;

            if (qtyElement.val() == "" || isNaN(qtyElement.val()) || parseInt(qtyElement.val()) < 0)
                qtyElement.val("0");
            var qty = Math.max(0, parseInt(qtyElement.val()));
            var numberLeft = parseInt(qtyElement.attr("max"));
            $(this).find("td:last > .text-danger.ticket-quantity-error").toggleClass("hide", qty <= numberLeft);
            $(this).find("td:last > .text-danger.event-overbook-error").toggleClass("hide", qty == 0);

            if (qty > numberLeft) {
                qtyElement.val(numberLeft);
                qty = numberLeft;
            }
            if (qty > 0) {
                var priceElement = $(this).find("input.donation-price");
                if (priceElement.length > 0 && priceElement.val() == "")
                    priceElement.val("0");
                var price = Math.max(0, parseFloat(priceElement.length > 0 ? priceElement.val() : $(this).data("price")));
                if ($(this).data("tiers")) {
                    var tiers = $(this).data("tiers");
                    var currentTier = ko.utils.arrayFirst(tiers, function (item) {
                        return item.Minimum <= qty && (item.Maximum == null || item.Maximum >= qty);
                    });
                    if (currentTier)
                        price = currentTier.Price;
                    $(this).find(".price-per").html("$" + price.toFixed(2));
                }

                var ticket = new ticketModel(
                    $(this).data("id"),
                    $(this).find("td:first strong").html(),
                    $(this).find(".description").html(),
                    $(this).data("taxable"),
                    price,
                    qty,
                    $(this).closest(".ticket-category").index(),
                    $(this).index(),
                    $(this).data("invoice") != false,
                    priceElement.length > 0,
                    $(this).find(".continuingEducationUnits").html(),
                    $(this).data("attendeerequired"),
                    $(this).data("showonform"),
                    $(this).data("noguestsallowed"));

                var numberOfSeats = parseFloat($(this).data("seats"));
                for (var i = 1; i <= qty * numberOfSeats; i++) {
                    attendees.push(new attendeeModel(ticket, i, $(this).find(".custom-fields").html()));
                }

                tickets.push(ticket);
            }
        });
        viewModel.Tickets(tickets);
    }

    // Adding attendees on change means there's no attendee DOM elements for the user to tab to. We record this
    // so we can focus on the first attendee's input when a user tabs out which is the preferred behavior.
    var tabbingOutOfTickets = false;
    $("#tickets input:last").keydown(function (evnt) {
        tabbingOutOfTickets = evnt.key == "Tab" && !evnt.altKey && !evnt.ctrlKey && !evnt.metaKey && !evnt.shiftKey
    });

    function UpdateAddons() {
        var addons = [];
        $("#products tbody tr").each(function () {
            var id = $(this).data("id");
            var qtyElement = $(this).find("td:last input.quantity");
            var qty = 1;
            if (qtyElement.length > 0) {
                var actualVal = qtyElement.val();
                var maxVal = qtyElement.data('maxAvailable') ? +(qtyElement.data('maxAvailable')) : 999;
                if (actualVal === "" || isNaN(actualVal))
                    qtyElement.val("");
                var intVal = qtyElement.val() === "" ? -1 : parseInt(qtyElement.val());

                if (intVal >= 0) {
                    qtyElement.val(intVal);
                }
                if (parseInt(qtyElement.val()) > maxVal) {
                    qtyElement.val(maxVal);
                    $("#max-available-msg_" + id).toggleClass('hide', false);
                }

                qty = Math.max(0, parseInt(qtyElement.val()));
            }

            if (qty > 0) {
                var subscription = qtyElement.attr("max");
                if (subscription && qty > 1) {
                    qtyElement.val(1);
                    qty = 1;
                }
                var priceElement = $(this).find("input.price:visible");
                if (priceElement.length > 0 && priceElement.val() == "")
                    priceElement.val("0");
                var price = Math.max(0, parseFloat(priceElement.length > 0 ? priceElement.val() : $(this).data("price")));
                if (price > 0) {
                    var autoRenew = $(this).find("input[type=checkbox]").length > 0 ? $(this).find("input[type=checkbox]").is(":checked") : false;
                    var addon = new addonModel($(this).data("id"), $(this).find("td:first strong").html(), $(this).find(".description").html(), $(this).data("taxable"), $(this).data("subscription"), price, qty, autoRenew);
                    addons.push(addon);
                }
            }
        });
        viewModel.Addons(addons);
    }

    UpdateTotals();
    UpdateAddons();

    $('[data-toggle=popover]').popover();
});